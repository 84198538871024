// src/components/HealthCheck.js
import React, { useState, useEffect } from 'react';
import UserService from '../service/UserService';
import packageJson from '../../../package.json'; // Importiere die Client-Version

const HealthCheck = ({ token }) => {
    const [healthInfo, setHealthInfo] = useState({
        serverVersion: '',
        status: '',
        authenticated: false,
        username: ''
    });
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHealthInfo = async () => {
            try {
                const data = await UserService.getHealthInfo(token); // Token übergeben
                setHealthInfo(data);
            } catch (err) {
                setError('Error fetching health info');
            }
        };

        fetchHealthInfo();
    }, [token]);

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div>
            <h2>Health Check</h2>
            <p><strong>Status:</strong> {healthInfo.status}</p>
            <p><strong>Server Version:</strong> {healthInfo.serverVersion}</p>
            <p><strong>Client Version:</strong> {packageJson.version}</p>
            {healthInfo.authenticated ? (
                <p><strong>Authenticated User:</strong> {healthInfo.username}</p>
            ) : (
                <p>No user authenticated</p>
            )}
        </div>
    );
};

export default HealthCheck;
