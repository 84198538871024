import React from 'react'
import '../../css/NavbarFooter.css'; // Achte darauf, dass der Pfad korrekt ist

const FooterComponent = () => {
    return (
        <div>
            <footer className='footer'>
                <span>Train-Crafter | All Right Reserved &copy; {new Date().getFullYear()} </span>
            </footer>
        </div>
    )
}

export default FooterComponent