// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../../css/NavbarFooter.css'; // Achte darauf, dass der Pfad korrekt ist
import { useConfirm } from '../common/ConfirmDialog';  // Importiere die ConfirmDialog-Komponente

function Navbar() {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const auth = useAuth();

    const { isAuthenticated, isAdmin, logout } = auth;

    const [confirm, ConfirmDialogs] = useConfirm();

    const handleLogout = async () => {
        const isConfirmed = await confirm('Are you sure you want to logout this user?');
        if (isConfirmed) {
            logout();
        }
    };

    return (
        <nav>
            <div className="nav-toggle" onClick={() => setMenuOpen(!isMenuOpen)}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
                {!isAuthenticated && <li><Link to="/">Login</Link></li>}
                {isAuthenticated && <li><Link to="/profile">Profile</Link></li>}
                {isAuthenticated && <li><Link to="/homework">Homework</Link></li>}
                {isAdmin && <li><Link to="/admin/user-management">User Management</Link></li>}
                {isAuthenticated && <li><Link onClick={handleLogout}>Logout</Link></li>}
            </ul>
            {ConfirmDialogs}
        </nav>
    );
}

export default Navbar;
