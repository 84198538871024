import React, { useState, useEffect } from 'react';
import '../../css/ConfirmDialog.css'; // Achte darauf, dass der Pfad korrekt ist

export const useConfirm = () => {
  const [dialog, setDialog] = useState(null);

  const show = (message) => {
    return new Promise((resolve) => {
      setDialog({ message, resolve });
    });
  };

  const handleResponse = (confirmed) => {
    if (dialog) {
      dialog.resolve(confirmed);
      setDialog(null); // Setzt den Dialog zurück
    }
  };

  const handleOverlayClick = () => {
    handleResponse(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      handleResponse(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [dialog]);

  const ConfirmDialog = dialog ? (
    <div
      className="confirm-dialog-overlay"
      onClick={handleOverlayClick}
    >
      <div
        className="confirm-dialog"
        onClick={(e) => e.stopPropagation()} // Verhindert das Schließen bei Klick auf den Dialog
      >
        <p>{dialog.message}</p>
        <button onClick={() => handleResponse(true)}>Ja</button>
        <button className="cancel" onClick={() => handleResponse(false)}>Nein</button>
      </div>
    </div>
  ) : null;

  return [show, ConfirmDialog];
};
