// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from './components/common/Navbar';
import FooterComponent from './components/common/Footer';
import { AuthProvider, useAuth } from './components/context/AuthContext'; // Importiere AuthProvider und useAuth
import LoginPage from './components/auth/LoginPage';
import RegistrationPage from './components/auth/RegistrationPage';
import UpdateUser from './components/userspage/UpdateUser';
import UserManagementPage from './components/userspage/UserManagementPage';
import ProfilePage from './components/userspage/ProfilePage';
import HomeworkPage from './components/userspage/HomeworkPage';
import ChangeUserInfo from './components/userspage/ChangeUserInfo';
import AddOrUpdateHomework from './components/userspage/AddOrUpdateHomework';

// PrivateRoute ist eine Hilfs-Komponente, um geschützte Routen zu erstellen
function PrivateRoute({ element, ...rest }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/login" />;
}

function AdminRoute({ element, ...rest }) {
  const { isAuthenticated, isAdmin } = useAuth();
  return isAuthenticated && isAdmin ? element : <Navigate to="/login" />;
}

// RedirectAuthenticated ist eine Hilfs-Komponente für Umleitungen bei bereits authentifizierten Benutzern
function RedirectAuthenticated({ redirectTo }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Navigate to={redirectTo} /> : <LoginPage />;
}

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <div className="content">
            <Routes>
              {/* Wenn der Benutzer bereits authentifiziert ist, wird er zum Profil weitergeleitet */}
              <Route exact path="/" element={<RedirectAuthenticated redirectTo="/profile" />} />
              <Route exact path="/login" element={<RedirectAuthenticated redirectTo="/profile" />} />

              {/* Geschützte Routen */}
              <Route exact path="/" element={<LoginPage />} />
              <Route exact path="/login" element={<LoginPage />} />
              <Route path="/profile" element={<PrivateRoute element={<ProfilePage />} />} />
              <Route path="/homework" element={<PrivateRoute element={<HomeworkPage />} />} />
              <Route path="/change-info/:userId" element={<PrivateRoute element={<ChangeUserInfo />} />} />
              <Route path="/add-homework" element={<PrivateRoute element={<AddOrUpdateHomework />} />} />
              <Route path="/edit-homework/:homeworkId" element={<PrivateRoute element={<AddOrUpdateHomework />} />} />

              {/* Admin routes */}
              <Route path="/register" element={<AdminRoute element={<RegistrationPage />} />} />
              <Route path="/admin/user-management" element={<AdminRoute element={<UserManagementPage />} />} />
              <Route path="/update-user/:userId" element={<AdminRoute element={<UpdateUser />} />} />

              {/* Fallback route */}
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </div>
          <FooterComponent />
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
