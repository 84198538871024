import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserService from '../service/UserService';
import '../../css/Homework.css';  // Importiere deine CSS-Datei
import { useConfirm } from '../common/ConfirmDialog';  // Importiere die ConfirmDialog-Komponente
//import HomeworkDetailPopup from '../common/HomeworkDetailPopup'; // Importiere die Popup-Komponente
import '../../css/HomeworkDetailPopup.css'; // Importiere die CSS-Datei für das Popup
import { FaTrash, FaPen, FaRegCheckSquare, FaRegSquare, FaBook, FaRegCalendarAlt, FaCheckDouble } from 'react-icons/fa';


function HomeworkPage() {
    const [homeworkList, setHomeworkList] = useState([]);
    const [filteredHomeworkList, setFilteredHomeworkList] = useState([]);

    // Filterstates für jede Spalte
    const [fachFilter, setFachFilter] = useState('');
    const [datumFilter, setDatumFilter] = useState('');
    const [textFilter, setTextFilter] = useState('');
    const [erledigtFilter, setErledigtFilter] = useState('Nein');  // Standard: nur nicht erledigte

    const [sortOrder, setSortOrder] = useState({ key: 'datum', order: 'asc' }); // Standard-Sortierung nach Datum aufsteigend
    const today = new Date();
    const isAdmin = UserService.isAdmin();

    const [subjectList, setSubjectList] = useState({});
    const [abbreviationList, setAbbreviationList] = useState({});
    const [subjectsLoaded, setSubjectsLoaded] = useState(false);

    const [confirm, ConfirmDialogs] = useConfirm();

    const [popupVisible, setPopupVisible] = useState(false); // Steuert die Sichtbarkeit des Popups
    const [selectedHomework, setSelectedHomework] = useState(null); // Speichert die ausgewählte Hausaufgabe

    const [isMobile, setIsMobile] = useState(false); // Steuert, ob der Bildschirm mobil istx

    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchSubjects(); // Fächer laden
                setSubjectsLoaded(true); // Fächer wurden geladen
            } catch (error) {
                console.error('Fehler beim Laden der Fächer:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (subjectsLoaded) {
            fetchHomework(); // Hausaufgaben nur laden, wenn Fächer geladen wurden
        }
    }, [subjectsLoaded]);

    useEffect(() => {
        applyFilter();
    }, [fachFilter, datumFilter, textFilter, erledigtFilter, homeworkList]);

    // Überwache die Bildschirmgröße
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Definiere einen Schwellenwert für "mobil"
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initiale Überprüfung beim ersten Render

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchSubjects = async () => {
        try {
            const token = localStorage.getItem('token');
            const country = 'germany'; // Hier könntest du das Land dynamisch setzen
            const response = await UserService.getSubjects(country, token);

            // Two separate lists: one for subjects (full names) and one for abbreviations
            const subjectList = {};   // For full names (e.g., { "ma": "Mathematik" })
            const abbreviationList = {}; // For abbreviations (e.g., { "germany.ma": "ma" })

            Object.keys(response).forEach(key => {
                const subject = response[key];
                const abbreviation = subject.abbreviation;
                const name = subject.name;

                // Convert first letter to uppercase
                const abbreviationUpper = abbreviation.charAt(0).toUpperCase() + abbreviation.slice(1);

                subjectList[`${country}.${abbreviation}`] = name; // Add to subject list
                abbreviationList[`${country}.${abbreviation}`] = abbreviationUpper; // Add to abbreviation list
            });

            console.log(subjectList);
            console.log(abbreviationList);

            // Set the lists in your state
            setSubjectList(subjectList);
            setAbbreviationList(abbreviationList);
        } catch (error) {
            console.error('Fehler beim Abrufen der Fächer:', error);
        }
    };

    const fetchHomework = async () => {
        try {
            const token = localStorage.getItem('token');
            let response;
            if (isAdmin) {
                response = await UserService.getAllHomework(token);
            } else {
                response = await UserService.getUserHomework(token);
            }

            // Verwende `mappedHomeworkList`, um die Fächer und Abkürzungen korrekt darzustellen
            const mappedHomeworkList = response.map(homework => ({
                ...homework,
                // Verwende subjectList, um den vollständigen Namen des Fachs zu erhalten
                fach: subjectList[homework.fachData] || homework.fachData,
                // Verwende abbreviationList, um die Abkürzung des Fachs zu erhalten
                abbreviation: abbreviationList[homework.fachData] || homework.fachData
            }));

            // Initiale Filter- und Sortierlogik anwenden
            setHomeworkList(mappedHomeworkList);
            applyFilter();  // Anwenden der Filter
        } catch (error) {
            console.error('Fehler beim Abrufen der Hausaufgaben:', error);
        }
    };

    // Filterlogik für jede Spalte
    const applyFilter = () => {
        const filtered = homeworkList.filter((homework) => {
            const matchesFach = homework.fach.toLowerCase().includes(fachFilter.toLowerCase());
            const matchesDatum = datumFilter ? homework.datum.includes(datumFilter) : true;
            const matchesText = homework.text.toLowerCase().includes(textFilter.toLowerCase());
            const matchesErledigt = erledigtFilter === '' ||
                (erledigtFilter === 'Ja' && homework.erledigt) ||
                (erledigtFilter === 'Nein' && !homework.erledigt);
            return matchesFach && matchesDatum && matchesText && matchesErledigt;
        });

        // Sortierung anwenden
        const sortedList = filtered.sort((a, b) => {
            if (sortOrder.key === 'datum') {
                return sortOrder.order === 'asc'
                    ? new Date(a.datum) - new Date(b.datum)
                    : new Date(b.datum) - new Date(a.datum);
            }
            return 0;
        });

        setFilteredHomeworkList(sortedList);
    };

    const handleSort = (key) => {
        const newSortOrder = sortOrder.key === key
            ? (sortOrder.order === 'asc' ? 'desc' : 'asc')
            : 'asc';

        setSortOrder({ key, order: newSortOrder });
        applyFilter(); // Filter nach Sortierung erneut anwenden
    };

    const getSortClass = (key) => {
        if (sortOrder.key === key) {
            return sortOrder.order === 'asc' ? 'asc' : 'desc';
        }
        return '';
    };

    const getRowColor = (isCompleted, dueDate) => {
        const dueDateObj = new Date(dueDate);
        const daysDiff = Math.ceil((dueDateObj - today) / (1000 * 60 * 60 * 24));

        if (isCompleted) {
            return 'completed';
        } else if (dueDateObj < today) {
            return 'overdue';
        } else if (daysDiff <= 3) {
            return 'due-soon';
        } else {
            return 'pending';
        }
    };

    const markAsCompleted = async (homeworkId) => {
        try {
            // Prompt for confirmation before deleting the user
            const isConfirmed = await confirm('Do you want to Complite this item?');

            const token = localStorage.getItem('token'); // Retrieve the token from localStorage
            if (isConfirmed) {
                await UserService.markHomeworkAsCompleted(homeworkId, token);
                fetchHomework(); // Reload the list after marking the homework as completed
            }
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Hausaufgabe:', error);
        }
    };

    const markAsNotCompleted = async (homeworkId) => {
        try {
            // Prompt for confirmation before deleting the user
            const isConfirmed = await confirm('Do you want to Not Complite this item?');

            const token = localStorage.getItem('token'); // Retrieve the token from localStorage
            if (isConfirmed) {
                await UserService.markHomeworkAsNotCompleted(homeworkId, token);
                fetchHomework(); // Reload the list after updating the homework status
            }
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Hausaufgabe:', error);
        }
    };

    const deleteHomework = async (homeworkId) => {
        try {
            const isConfirmed = await confirm('Are you sure you want to delete this homework?');
            const token = localStorage.getItem('token');
            if (isConfirmed) {
                await UserService.deleteHomework(homeworkId, token);
                fetchHomework();
            }
        } catch (error) {
            console.error('Error deleting homework:', error);
        }
    };

    const handleEditClick = (homework) => {
        navigate(`/edit-homework/${homework.id}`);
    }

    const handleButtonClick = (e, homework, action) => {
        e.stopPropagation(); // Verhindert, dass der Click-Event die Zeile auslöst
        action(homework); // Ruft die entsprechende Aktion auf
    };

    // Funktion zum Kürzen des Datums
    const formatDate = (date) => {
        const options = isMobile ? { day: 'numeric', month: 'numeric' } : { day: 'numeric', month: 'numeric', year: 'numeric' };
        return new Date(date).toLocaleDateString('de-DE', options);
    };

    // Öffne Popup mit den Details der ausgewählten Hausaufgabe
    const openPopup = (homework) => {
        if (isMobile) { // Popup nur öffnen, wenn der Bildschirm mobil ist
            console.log("openPopup");
            setSelectedHomework(homework);
            setPopupVisible(true);
        }
    };

    // Schließe das Popup
    const closePopup = () => {
        console.log("closePopup");
        setSelectedHomework(null);
        setPopupVisible(false);
    };

    //HTML

    return (
        <div className={`homework-container ${isAdmin ? 'admin-view' : 'user-view'}`}>
            <h2>Homework Page</h2>
            <button className='reg-button'><Link to="/add-homework">Add Homework</Link></button>

            {/* Filter Inputs für jede Spalte */}
            <div className="filter-container">
                <input
                    type="text"
                    placeholder="Filter by Fach"
                    value={fachFilter}
                    onChange={(e) => setFachFilter(e.target.value)}
                />
                <input
                    type="date"
                    placeholder="Filter by Datum"
                    value={datumFilter}
                    onChange={(e) => setDatumFilter(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filter by Text"
                    value={textFilter}
                    onChange={(e) => setTextFilter(e.target.value)}
                />
                <select
                    value={erledigtFilter}
                    onChange={(e) => setErledigtFilter(e.target.value)}
                >
                    <option value="">Alle</option>
                    <option value="Ja">Ja</option>
                    <option value="Nein">Nein</option>
                </select>
            </div>

            <table>
                <thead>
                    <tr>
                        <th className={`sortable-header ${getSortClass('fach')}`} onClick={() => handleSort('fach')}>
                            {isMobile ? (
                                // Zeigt ein Icon an, wenn shortenDisplay aktiviert ist
                                <FaBook className="sort-icon" />
                            ) : (
                                // Zeigt den Text "Fach" an, wenn shortenDisplay deaktiviert ist
                                'Fach'
                            )}
                            <span className={`sort-arrow ${getSortClass('fach')}`}></span>
                        </th>
                        <th className={`sortable-header ${getSortClass('datum')}`} onClick={() => handleSort('datum')}>
                            {isMobile ? (
                                // Zeigt ein Icon an, wenn shortenDisplay aktiviert ist
                                <FaRegCalendarAlt className="sort-icon" />
                            ) : (
                                // Zeigt den Text "Fach" an, wenn shortenDisplay deaktiviert ist
                                'Fach'
                            )}
                            <span className={`sort-arrow ${getSortClass('datum')}`}></span>
                        </th>
                        <th className={`sortable-header ${getSortClass('text')}`} onClick={() => handleSort('text')}>
                            Text
                            <span className={`sort-arrow ${getSortClass('text')}`}></span>
                        </th>
                        <th className={`sortable-header ${getSortClass('erledigt')} `} onClick={() => handleSort('erledigt')}>
                            {isMobile ? (
                                // Zeigt ein Icon an, wenn shortenDisplay aktiviert ist
                                <FaCheckDouble className="sort-icon" />
                            ) : (
                                // Zeigt den Text "Fach" an, wenn shortenDisplay deaktiviert ist
                                'Fach'
                            )}
                            <span className={`sort-arrow ${getSortClass('erledigt')}`}></span>
                        </th>
                        {isAdmin && <th className="hide-on-mobile" onClick={() => handleSort('id')}>Id</th>}
                        {isAdmin && <th className="hide-on-mobile" onClick={() => handleSort('timestamp')}>Timestamp</th>}
                        {isAdmin && <th className="hide-on-mobile" onClick={() => handleSort('user_id.id')}>User</th>}
                        <th className="hide-on-mobile" >Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredHomeworkList.map(homework => (
                        <tr key={homework.id} onClick={() => openPopup(homework)}>
                            <td className={getRowColor(homework.erledigt, homework.datum)}>{isMobile ? homework.abbreviation : homework.fach}</td>
                            <td>{formatDate(homework.datum)}</td>
                            <td>{homework.text}</td>
                            <td className="" >
                                {/*homework.erledigt ? 'Ja' : 'Nein'*/}
                                {!homework.erledigt && (
                                    <button className='complete-button' onClick={(e) => handleButtonClick(e, homework.id, markAsCompleted)}><FaRegSquare className="icon square-icon" /></button>
                                )}
                                {homework.erledigt && (
                                    <button className='not-complete-button' onClick={(e) => handleButtonClick(e, homework.id, markAsNotCompleted)}><FaRegCheckSquare className="icon check-square-icon" /></button>
                                )}
                            </td>
                            {isAdmin && <td className="hide-on-mobile" >{homework.id}</td>}
                            {isAdmin && <td className="hide-on-mobile" >{homework.timestamp}</td>}
                            {isAdmin && <td className="hide-on-mobile" >{homework.user_id.id}</td>}
                            <td className="action hide-on-mobile" >
                                <button className='delete-button' onClick={() => deleteHomework(homework.id)}><FaTrash className="icon delete-icon" /></button>
                                <button onClick={() => handleEditClick(homework)}><FaPen className="icon edit-icon" /></button>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Popup für Hausaufgabendetails */}
            {popupVisible && selectedHomework && (
                <div className="popup">
                    <div className="popup-content">
                        <h3>Details der Hausaufgabe</h3>
                        <p><strong>Fach:</strong> {selectedHomework.fach}</p>
                        <p><strong>Datum:</strong> {new Date(selectedHomework.datum).toLocaleDateString('de-DE')}</p>
                        <p><strong>Text:</strong> {selectedHomework.text}</p>
                        {isAdmin && <p><strong>Id:</strong> {selectedHomework.id}</p>}
                        {isAdmin && <p><strong>Timestamp:</strong> {selectedHomework.timestamp}</p>}
                        {isAdmin && <p><strong>User:</strong> {selectedHomework.user_id.id}</p>}
                        <p><strong>Erledigt:</strong> {selectedHomework.erledigt ? 'Ja' : 'Nein'}</p>
                        {!selectedHomework.erledigt && (
                            <button className='complete-button' onClick={() => markAsCompleted(selectedHomework.id)}>Erledigt</button>
                        )}
                        {selectedHomework.erledigt && (
                            <button className='not-complete-button' onClick={() => markAsNotCompleted(selectedHomework.id)}>Nicht Erledigt</button>
                        )}
                        <button className='delete-button' onClick={() => deleteHomework(selectedHomework.id)}>Delete</button>
                        <button><Link to={`/edit-homework/${selectedHomework.id}`}>Edit</Link></button>
                        <button onClick={closePopup}>Schließen</button>
                    </div>
                </div>
            )}

            {/* ConfirmDialog Komponente */}
            {ConfirmDialogs}
        </div>
    );
}

export default HomeworkPage;
